<template>
  <v-card
    flat
    class="card-effect card-border px-8"
    @click.native="showData"
    :light="!$vuetify.theme.dark"
    :style="{
      border: !$vuetify.theme.dark
        ? '1px solid #dbdbdb8c'
        : '1px solid #dbdbdb0c',
    }"
  >
    <div class="text-center">
      <v-avatar size="140">
        <img
          :src="require('../../src/assets/' + image)"
          alt="Image not found"
        />
      </v-avatar>
      <p class="pt-3" style="font-size: 23px">
        {{ title }}
      </p>
      <p style="font-size: 13px">
        {{ message }}
      </p>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    image: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
  },
  methods: {
    showData() {
      this.$emit("showData");
    },
  },
};
</script>

<style scoped lang="scss">
.card-effect:hover {
  box-shadow: 10px 10px 43px 0px rgba(0, 0, 0, 0.15);
  border-color: transparent;
  cursor: pointer;
}
.card-border {
  border-radius: 8px;
  border-left: 3px solid var(--v-primary-base) !important;
  display: inline-block !important;
}

.card-effect {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  height: 260px;
  width: 360px;
  text-align: center;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease-out;
}

@media (min-width: 600px) {
  .card-effect {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
    height: 360px;
    width: 260px;
    text-align: center;
    cursor: pointer;
    display: block;
    transition: all 0.2s ease-out;
  }
  .card-border {
    border-radius: 8px;
    border-top: 5px solid var(--v-primary-base) !important;
    border-left: 1px solid #ebebeb !important;
    display: inline-block !important;
  }
}
</style>
