<template>
  <div style="height: 100%">
    <v-row no-gutters align="center" style="height: 100%">
      <v-col
        v-if="showCompany"
        xl="6"
        lg="6"
        md="6"
        sm="6"
        cols="12"
        class="pa-5 text-align__company"
      >
        <card-item
          style="margin: auto; display: block !important"
          :image="'company.svg'"
          :title="$i18n.t('ADMIN.companyCard')"
          :message="$i18n.t('ADMIN.companyMessage')"
          @showData="showData('admin/companies')"
        />
      </v-col>
      <v-col
        :xl="showCompany ? '6' : '12'"
        :lg="showCompany ? '6' : '12'"
        :md="showCompany ? '6' : '12'"
        :sm="showCompany ? '6' : '12'"
        cols="12"
        class="pa-5 text-align__user"
      >
        <card-item
          style="margin: auto; display: block !important"
          :image="'user.svg'"
          :title="$i18n.t('ADMIN.userCard')"
          :message="$i18n.t('ADMIN.userMessage')"
          @showData="showData('admin/users')"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CardItem from "@/components/CardItem.vue";

export default {
  components: {
    CardItem,
  },
  methods: {
    showData(route) {
      this.$router.push(route);
    },
  },
  created() {
    if (
      this.$store.state.Auth.token.role === "superuser" ||
      this.$store.state.Auth.token.role === "admin"
    ) {
      this.showCompany = true;
    }
  },
  data() {
    return {
      showCompany: false,
    };
  },
};
</script>

<style lang="scss">
.text-align__user {
  text-align: center;
}
.text-align__company {
  text-align: center;
}
@media (min-width: 600px) {
  .text-align__user {
    text-align: start;
  }
  .text-align__company {
    text-align: end;
  }
}
</style>
